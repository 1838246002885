<template>
  <div id="myLogin">
    <el-dialog title="登录" width="300px" center :visible.sync="isLogin" :close-on-click-modal="false">
      <el-form :model="loginForm" :rules="loginRules" status-icon ref="loginForm" class="demo-ruleForm">
        <el-form-item prop="name">
          <el-input prefix-icon="el-icon-user" placeholder="请输入账号或手机号码" v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="请输入密码"
              v-model="loginForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code" v-if="captchaOnOff">
          <el-input
              prefix-icon="el-icon-key"
              v-model="loginForm.code"
              auto-complete="off"
              placeholder="验证码"
              style="width: 63%"
          >
          </el-input>
          <div class="login-code">
            <img :src="codeUrl" @click="getCode" class="login-code-img"/>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" @click="Login" style="width:100%;">登录</el-button>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="18">
            <el-button type="text" @click="toForget">忘记密码</el-button>
          </el-col>
          <el-col :span="6">
            <el-button type="text" @click="showReg">用户注册</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import {getCodeImg, getInfo, getUserResume, login} from '@/api/User'
import {setToken} from '@/utils/auth'

export default {
  name: "MyLogin",
  data() {
    return {
      codeUrl: "",
      // 验证码开关
      captchaOnOff: true,
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [
          {required: true, trigger: "blur", message: "请输入您的账号"}
        ],
        password: [
          {required: true, trigger: "blur", message: "请输入您的密码"}
        ],
        code: [{required: true, trigger: "change", message: "请输入验证码"}]
      },
    };
  },
  watch: {
    isLogin: function (val) {
      if (val) {
        this.getCode()
      }
    }
  },
  created() {
    this.getCode()
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get() {
        return this.$store.getters.getShowLogin;
      },
      set(val) {
        this.$refs["loginForm"].resetFields();
        this.setShowLogin(val);
      }
    }
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.data.captchaOnOff === undefined ? true : res.data.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.loginForm.uuid = res.data.uuid;
        }
      });
    },
    Login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          login(this.loginForm).then(response => {
            // “001”代表登录成功，其他的均为失败
            if (response.data.code === 200) {
              setToken(response.data.token)
              getInfo().then(res => {
                if (res.status === 200) {
                  //判断当前登录用户的角色，如果不是注册用户则提示登录失败
                  let inRegUser = false
                  for (let inx in res.data.user.roles) {
                    let role = res.data.user.roles[inx]
                    if (role.roleKey === 'regUser') {
                      inRegUser = true
                      break;
                    }
                  }
                  if (inRegUser) {
                    // 隐藏登录组件
                    this.isLogin = false
                    //获取用户信息
                    // 登录信息存到本地
                    let user = JSON.stringify(res.data.user)
                    sessionStorage.setItem('user', user)
                    // 登录信息存到vuex
                    this.setUser(res.data.user)
                    //获reg-footer取用户简历
                    getUserResume().then(response => {
                          if (response.status === 200 && response.data.code === 200 && response.data.data.id) {
                            //将当前登录用户信息存储在sessionStorage中
                            let resume = response.data.data
                            sessionStorage.setItem('resume', JSON.stringify(resume))
                          }
                          // 弹出通知框提示登录成功信息
                          this.notifySucceed('登录成功！')
                          this.$router.go(0)
                        }
                    )
                  } else {
                    // 清空输入框的校验状态
                    this.$refs["loginForm"].resetFields();
                    // 弹出通知框提示登录失败信息
                    this.notifyError('用户不存在/密码错误');
                  }
                }
              })
            } else {
              // 清空输入框的校验状态
              this.$refs["loginForm"].resetFields();
              // 弹出通知框提示登录失败信息
              this.notifyError(response.data.msg);
            }
            this.getCode();
          }).catch(err => {
            if (this.captchaOnOff) {
              this.getCode();
            }
            return Promise.reject(err);
          })
        }
      });
    },
    toForget() {
      this.isLogin = false
      this.$router.push({path: "/forget"})
    },
    showReg() {
      this.isLogin = false
      this.$emit('fromLogin', true)
    }
  }
};
</script>
<style scoped>
.login-code {
  width: 33%;
  height: 38px;
  float: right;
}

.login-code > img {
  cursor: pointer;
  vertical-align: middle;
}

.login-code-img {
  height: 38px;
  width: 82px
}

.reg-footer {
  text-align: justify;
}
</style>
