<template>
  <div id="app" name="app">
    <el-container>
      <!-- 顶部导航栏 -->
      <div class="topbar">
        <div class="nav">
          <a href="javascript:void(0)"><img src="./assets/imgs/logo.png" alt @click="toIndex"/></a>
          <ul>
            <li v-if="!this.$store.getters.getUser">
              <el-button type="text" @click="login">登录</el-button>
              <span class="sep">|</span>
              <el-button type="text" @click="register = true">注册</el-button>
            </li>
            <li v-else>
              <img :src="avatar" class="user-avatar"> {{ this.$store.getters.getUser.nickName }} &nbsp;
              <el-popover placement="top" width="180" v-model="visible">
                <p>确定退出登录吗？</p>
                <div style="text-align: right; margin: 10px 0 0">
                  <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="logout">确定</el-button>
                </div>
                <el-button type="text" slot="reference">退出登录</el-button>
              </el-popover>
            </li>
          </ul>
        </div>
      </div>
      <!-- 顶部导航栏END -->

      <!-- 登录模块 -->
      <MyLogin :register="register" @fromLogin="isRegister"></MyLogin>
      <!-- 注册模块 -->
      <MyRegister :register="register" @fromReg="isRegister"></MyRegister>

      <!-- 主要区域容器 -->
      <el-main>
        <router-view></router-view>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <el-footer>
        <div class="footer">
        </div>
      </el-footer>
      <!-- 底栏容器END -->
    </el-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {removeToken} from '@/utils/auth'
import {logout} from "@/api/User";

export default {
  beforeUpdate() {
    this.activeIndex = this.$route.path;
  },
  data() {
    return {
      activeIndex: "", // 头部导航栏选中的标签
      search: "", // 搜索条件
      register: false, // 是否显示注册组件
      visible: false, // 是否退出登录
      avatar: ""//用户头像地址
    };
  },
  created() {
    // sessionStorage，判断用户是否已经登录
    if (sessionStorage.getItem("user")) {
      // 如果已经登录，设置vuex登录状态
      this.setUser(JSON.parse(sessionStorage.getItem("user")));
    }
  },
  computed: {
    ...mapGetters(["getUser", "getNum"])
  },
  watch: {
    // 获取vuex的登录状态
    getUser: function (user) {
      this.avatar = user.avatar === "" ? require("@/assets/imgs/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
    }
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
    login() {
      // 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
      this.setShowLogin(true);
    },
    // 退出登录
    logout() {
      logout().then(response => {
        if (response.status === 200) {
          this.visible = false;
          // 清空本地登录信息，与当前用户简历
          sessionStorage.setItem("user", "");
          sessionStorage.setItem("resume", "");
          // 清空vuex登录信息
          this.setUser("");
          removeToken();
          this.notifySucceed("成功退出登录");
          if (this.$route.path === '/') {
            this.$router.go(0)
          } else {
            this.$router.push({path: "/"});
          }
        }
      })
    },
    // 接收注册子组件传过来的数据
    isRegister(val) {
      this.register = val;
    },
    toIndex() {
      this.$router.push({path: "/"});
    }
  }
};
</script>

<style>
/* 全局CSS */
* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}

#app .el-header {
  padding: 0;
}

#app .el-main {
  min-height: 300px;
  padding: 0 0 68px 0;
  background-color: #f5f5f5;
}

#app .el-footer {
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
}

/* 全局CSS END */

/* 顶部导航栏CSS */
.topbar {
  height: 80px;
  background-color: #ffffff;
}

.topbar .nav {
  max-width: 1440px;
  margin: 0 auto;
}

.topbar .nav ul {
  float: right;
}

.topbar .nav li {
  float: left;
  height: 80px;
  color: #333333;
  font-size: 14px;
  text-align: center;
  line-height: 80px;
  margin-left: 20px;
}

.topbar .nav .sep {
  color: #b0b0b0;
  font-size: 12px;
  margin: 0 5px;
}

.topbar .nav li .el-button {
  color: #333333;
}

.topbar .nav .el-button:hover {
  color: #1677FF;
}

.topbar .nav li a {
  color: #b0b0b0;
}

.topbar .nav a:hover {
  color: #1677FF;
}

/* 顶部导航栏CSS END */

/* 顶栏容器CSS */
.el-header .el-menu {
  max-width: 1225px;
  margin: 0 auto;
}

.el-header .logo {
  height: 60px;
  width: 189px;
  float: left;
  margin-right: 100px;
}

/* 顶栏容器CSS END */

/* 底栏容器CSS */
.footer {
  width: 100%;
  text-align: center;
  background: #1677FF;
  height: 200px;
}

/* 底栏容器CSS END */

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  vertical-align: middle;
}
</style>
