import axios from 'axios';
import router from "@/router";
import store from "@/store";
import { getToken } from '@/utils/auth'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 100000
})

service.interceptors.request.use(
    config => {
        // 是否需要设置 token
        const isToken = (config.headers || {}).isToken === false
        if (getToken() && !isToken) {
            config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config;
    },
    error => {
        // 跳转error页面
        router.push({ path: "/error" });
        return Promise.reject(error);
    }
);
// 全局响应拦截器
service.interceptors.response.use(
    res => {
        if (res.data.code === 401) {
            // 401表示没有登录
            // 提示没有登录
            // Vue.prototype.notifyError(res.data.msg);
            // 修改vuex的showLogin状态,显示登录组件
            store.dispatch("setShowLogin", true);
        }
        // if (res.data.code === 500) {
        //     // 500表示服务器异常
        //     // 跳转error页面
        //     router.push({ path: "/error" });
        // }
        return res;
    },
    error => {
        // 跳转error页面
        router.push({ path: "/error" });
        return Promise.reject(error);
    }
);

export default service
