import request from '@/utils/request'

// 获取验证码
export function getCodeImg() {
    return request({
        url: '/captchaImage',
        headers: {
            isToken: false
        },
        method: 'get',
        timeout: 20000
    })
}

// 登录方法
export function login(loginUser) {
    return request({
        url: '/login',
        headers: {
            isToken: false
        },
        method: 'post',
        data: loginUser
    })
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/getInfo',
        method: 'get'
    })
}

// 退出方法
export function logout() {
    return request({
        url: '/logout',
        method: 'post'
    })
}

// 判断账号是否重复
export function isUserNameExits(name) {
    return request({
        url: '/client/registerUserNameExists/' + name,
        method: 'get'
    })
}

// 判断手机号码是否重复
export function isPhoneNumberExits(phone) {
    return request({
        url: '/client/registerUserPhoneExists/' + phone,
        method: 'get'
    })
}

// 判断图形验证码是否正确
export function sendSmsValidateCode(phoneNumber, picCodeUuid, picCode) {
    return request({
        url: '/client/sendSmsValidateCode/',
        data: {phoneNumber, picCodeUuid, picCode},
        method: 'post'
    })
}

// 验证短信验证码
export function validateSmsCode(phoneNumber, code) {
    return request({
        url: '/client/validateSmsCode/',
        data: {phoneNumber, code},
        method: 'post'
    })
}

// 重置密码
export function restPwd(phoneNum, code, newPassword) {
    return request({
        url: '/client/restPwd/',
        data: {phoneNum, code, newPassword},
        method: 'post'
    })
}

// 用户注册
export function registerUser(user) {
    return request({
        url: '/client/registerUser/',
        data: user,
        method: 'post'
    })
}

// 获取当前登录人员报名简历
export function getUserResume() {
    return request({
        url: '/client/getUserResume',
        method: 'get'
    })
}

// 下载当前用户简历
export function downloadUserResume() {
    return request({
        url: '/client/downloadUserResume',
        method: 'get'
    })
}
