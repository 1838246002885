<template>
  <div id="register">
    <el-dialog title="注册" width="500px" center :visible.sync="isRegister" :close-on-click-modal="false">
      <el-form
          :model="RegisterUser"
          :rules="rules"
          status-icon
          ref="ruleForm"
          class="demo-ruleForm"
      >
        <el-form-item prop="userName">
          <el-input
              prefix-icon="el-icon-user-solid"
              placeholder="请输入账号，字母开头,长度5-16之间,允许字母数字下划线"
              v-model="RegisterUser.userName"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phonenumber">
          <el-input
              prefix-icon="el-icon-phone"
              placeholder="请输入手机号码"
              v-model="RegisterUser.phonenumber"
          ></el-input>
        </el-form-item>
        <el-form-item prop="imgCode">
          <el-input
              prefix-icon="el-icon-key"
              v-model="RegisterUser.imgCode"
              auto-complete="off"
              placeholder="图形验证码"
              style="width: 63%"
          >
          </el-input>
          <div class="reg-code">
            <img :src="codeUrl" @click="getCode" class="reg-code-img"/>
          </div>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
              prefix-icon="el-icon-chat-line-square"
              placeholder="请输入短信验证码"
              v-model="RegisterUser.code" style="width: 63%"
          ></el-input>
          <el-button :disabled="btnSmsDisabled" plain class="btn-send-code" size="medium" @click="sendSmsCode">
            {{ btnSmsText }}
          </el-button>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              prefix-icon="el-icon-view"
              type="password"
              placeholder="请输入密码,字母开头,长度6-18之间,允许字母数字和下划线"
              v-model="RegisterUser.password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="repassword">
          <el-input
              prefix-icon="el-icon-view"
              type="password"
              placeholder="请再次输入密码"
              v-model="RegisterUser.repassword"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="btnRegDisabled" size="medium" type="primary" @click="Register" style="width:100%;">注册
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {getCodeImg, isPhoneNumberExits, isUserNameExits, registerUser, sendSmsValidateCode} from "@/api/User";

export default {
  name: "MyRegister",
  props: ["register"],
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      if (userNameRule.test(value)) {
        //判断数据库中是否已经存在该用户名
        isUserNameExits(value).then(response => {
          if (response.status === 200) {
            if (response.data.data) {
              return callback(new Error("该账号已被注册，请重新输入"));
            } else {
              return callback();
            }
          } else {
            return Promise.reject(response.statusText);
          }
        }).catch(error => {
          return Promise.reject(error);
        })
      } else {
        return callback(new Error("字母开头,长度5-16之间,允许字母数字下划线"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      if (passwordRule.test(value)) {
        return callback();
      } else {
        return callback(
            new Error("字母开头,长度6-18之间,允许字母数字和下划线")
        );
      }
    };
    // 确认密码的校验方法
    let validateConfirmPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入确认密码"));
      }
      // 校验是否以密码一致
      if (this.RegisterUser.password !== "" && value === this.RegisterUser.password) {
        return callback();
      } else {
        return callback(new Error("两次输入的密码不一致"));
      }
    };
    // 校验手机号码
    let validatePhoneNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号码"));
      }
      //校验手机号码格式
      const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (phoneReg.test(value)) {
        //校验手机号码是否重复
        isPhoneNumberExits(value).then(response => {
          if (response.status === 200) {
            if (response.data.data) {
              return callback(new Error("该账手机已被注册，请重新输入"));
            } else {
              return callback();
            }
          } else {
            return Promise.reject(response.statusText);
          }
        }).catch(error => {
          return Promise.reject(error);
        })
      } else {
        return callback(new Error("错误的手机号码"));
      }
    };
    return {
      isRegister: false, // 控制注册组件是否显示
      codeUrl: "",
      uuid: "",
      btnSmsDisabled: false,
      btnSmsText: "发送短信验证码",
      btnSmsInterval: null,
      btnSmsEnableSecond: 120,
      btnRegDisabled: true,
      RegisterUser: {
        imgCode: null,
        userName: "",
        password: "",
        repassword: "",
        code: "",
        phonenumber: ""
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        userName: [{validator: validateName}],
        password: [{validator: validatePass}],
        repassword: [{validator: validateConfirmPass}],
        code: [{required: true, message: "请输入短信验证码"}],
        phonenumber: [{validator: validatePhoneNumber}],
        imgCode: [{required: true, message: "请输入图形证码"}]
      }
    };
  },
  watch: {
    // 监听父组件传过来的register变量，设置this.isRegister的值
    register: function (val) {
      if (val) {
        this.isRegister = val;
      }
    },
    // 监听this.isRegister变量的值，更新父组件register变量的值
    isRegister: function (val) {
      if (!val) {
        this.$refs["ruleForm"].resetFields();
        this.$emit("fromReg", val);
      }else{
        this.getCode()
      }
    }
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.data.captchaOnOff === undefined ? true : res.data.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.uuid = res.data.uuid;
        }
      });
    },
    Register() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.btnRegDisabled = true //禁用注册按钮
          registerUser(this.RegisterUser).then(response => {
            if (response.status === 200) {
              this.btnRegDisabled = false
              if (response.data.code === 200) {
                this.notifySucceed('注册成功')
                this.isRegister = false
              } else {
                this.notifyError('验证码错误')
              }
            }
          }).catch(err => {
            return Promise.reject(err);
          })
        }
      })
    },
    sendSmsCode() {

      let flag = true
      //首先校验手机号码是否正确
      this.$refs.ruleForm.validateField("phonenumber", errorMsg => {
        if (errorMsg) {
          flag = false
        }
      })

      if (flag) {//如果手机号码正确，判断用户是否输入图形验证码
        this.$refs.ruleForm.validateField("imgCode", errorMsg => {
          if (!errorMsg) {
            // 发送短信验证码
            sendSmsValidateCode(this.RegisterUser.phonenumber, this.uuid, this.RegisterUser.imgCode).then(response => {
              if (response.status === 200 && response.data.code === 200) {
                // 弹出通知框提示登录成功信息
                this.notifySucceed('验证码已发送！');

                this.btnRegDisabled = false //启用注册按钮
                // 120秒禁用发送短信按钮
                this.btnSmsDisabled = true

                this.btnSmsInterval = setInterval(() => {
                  if (this.btnSmsEnableSecond > 0) {
                    this.btnSmsText = this.btnSmsEnableSecond + 's'
                    this.btnSmsEnableSecond--
                  } else {
                    clearInterval(this.btnSmsInterval)
                    this.btnSmsText = '发送短信验证码'
                    this.btnSmsEnableSecond = 120
                    this.btnSmsDisabled = false
                  }
                }, 1000)
              } else {
                this.btnRegDisabled = true //禁用注册按钮
                // 弹出通知框提示登录失败信息
                this.notifyError(response.data.msg);
              }
            }).catch(err => {
              this.btnRegDisabled = true //禁用注册按钮
              return Promise.reject(err)
            })
            this.getCode()
          }
        })
      }
    }
  },
  created() {
    this.getCode()
  },
  beforeDestroy() {
    clearInterval(this.btnSmsInterval)
  }
};
</script>
<style scoped>
.reg-code {
  width: 33%;
  height: 38px;
  float: right;
}

.reg-code > img {
  cursor: pointer;
  vertical-align: middle;
}

.reg-code-img {
  height: 38px;
  width: 100%
}

.btn-send-code {
  height: 38px;
  width: 33%;
  margin-left: 18px;
}
</style>
