<template>
  <div class="progress">
    <el-steps :active="step" align-center>
      <el-step title="应聘报名" icon="el-icon-user"></el-step>
      <el-step title="报名状态" icon="el-icon-postcard"></el-step>
      <el-step title="审核状态" icon="el-icon-bank-card"></el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  name: "Progress",
  props: ['step']
}
</script>

<style scoped>
.progress {
  height: 140px;
  background-color: #ffffff;
  margin-top: 32px;
  text-align: center;
  padding-top: 100px;
  font-size: 16px;
}
</style>
